import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  HStack,
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Grid,
  GridItem,
  Text,
  Divider,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import ErrorMessage from "../components/Session/NotFound";
import Loading from "../components/Global/LoadingScreen";

import { API, Payload } from "../api/api";

const BackgroundColor = {
  backdropFilter: "blur(16px) saturate(200%)",
  WebkitBackdropFilter: "blur(16px) saturate(200%)",
  backgroundColor: "rgba(17, 25, 40, 0.50)",
  borderRadius: "12px",
  border: "1px solid rgba(255, 255, 255, 0.125)",
  color: "rgba(255, 255, 255, 0.85)",
};



interface SafetyCoachingEvent {
  Title: string | null;
  Code: string | null;
  CreatedBy: string | null;
  Description: string | null;
  EventID: string | null;
  DriverCode: string | null;
  ID: string | null;
}

interface SafetyCoachingDetail {
  ID: string | null;
  DriverName: string | null;
  DriverCode: string | null;
  DriverLeader: string | null;
  Assigned: string | null;
  Division: string | null;
  Created: string | null;
  CreatedBy: string | null;
  UniqueID: string | null;
  Status: string;
  Note: string | null;
  Comment: string | null;
  Completed: string | null;
  Success: boolean;
  DriverPhone: string | null;
  DriverEmail: string | null;
  CoachingEvent: string | null;
  CoachingType: string | null;
  Events: SafetyCoachingEvent[];
}

export default function Archive() {
  const { ID } = useParams<{ ID: string }>();
  const [data, setData] = useState<SafetyCoachingDetail[]>([]);
  const [loading, setLoading] = useState(true);
  const [filteredEvents, setFilteredEvents] = useState<SafetyCoachingEvent[]>(
    []
  );

  useEffect(() => {
    const payload: Payload = {
      Process: "ViewArchive",
      ID: ID,
    };
    console.log(payload);
    API(payload)
      .then((responseData) => {
        setLoading(false);
        setData(responseData);
        console.log(responseData);
      })
      .catch((error) => {
        setLoading(false);
        console.error("API call error:", error);
      });
  }, [ID]);

  //Filter event data for the current event tab
  useEffect(() => {
    const filtered = data[0]?.Events.filter((event) => event.ID === ID);
    setFilteredEvents(filtered || []);
  }, [data, ID]);

  if (loading) {
    return <Loading />;
  }

  // Check if data is not null and contains at least one item with Success property
  if (data && data.length > 0 && data[0]?.Success) {
    return (
      <HStack spacing={1} align="stretch" maxW="98%" mx="auto" p={4}>
        <Box
          p={5}
          bg="gray.50"
          borderRadius="md"
          boxShadow="md"
          width="60%"
          sx={BackgroundColor}
        >
          <Tabs size="sm" isFitted variant="enclosed">
            <TabList>
              <Tab _selected={{ color: "white", bg: "orange" }}>
                Current Safety Events
              </Tab>
              <Tab _selected={{ color: "white", bg: "orange" }}>
                All Safety Events
              </Tab>
              <Tab _selected={{ color: "white", bg: "orange" }}>
                Safety Coaching History
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                {/* Safety Coaching Events */}
                <Box
                  width="100%"
                  height="550px"
                  position="relative"
                  overflowY="auto"
                >
                  {" "}
                  <Table variant="simple" size="sm">
                    <Thead position="sticky" top="0" bg="white" zIndex="1">
                      <Tr>
                        <Th color="blue.300">Title</Th>
                        <Th color="blue.300">Code</Th>
                        <Th color="blue.300">Requested By</Th>
                        <Th color="blue.300">Description</Th>
                        <Th color="blue.300">Event ID</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {filteredEvents.map((event, index) => (
                        <Tr key={index}>
                          <Td>{event.Title}</Td>
                          <Td>{event.Code}</Td>
                          <Td>{event.CreatedBy}</Td>
                          <Td>{event.Description}</Td>
                          <Td>{event.EventID}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Box>
              </TabPanel>
              <TabPanel>
                {/* All Safety Coaching Events */}
                <Box
                  width="100%"
                  height="550px"
                  position="relative"
                  overflowY="auto"
                >
                  {" "}
                  <Table variant="simple" size="sm">
                    <Thead position="sticky" top="0" bg="white" zIndex="1">
                      <Tr>
                        <Th color="blue.300">Title</Th>
                        <Th color="blue.300">Code</Th>
                        <Th color="blue.300">Requested By</Th>
                        <Th color="blue.300">Description</Th>
                        <Th color="blue.300">Event ID</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {data[0]?.Events.map((event, index) => (
                        <Tr key={index}>
                          <Td>{event.Title}</Td>
                          <Td>{event.Code}</Td>
                          <Td>{event.CreatedBy}</Td>
                          <Td>{event.Description}</Td>
                          <Td>{event.EventID}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Box>
              </TabPanel>
             
            </TabPanels>
          </Tabs>
        </Box>
        <Grid templateColumns="repeat(2, 1fr)" gap={1} flex="1">
          {/* Driver's Personal Details */}
          <GridItem
            p={4}
            shadow="md"
            colSpan={1}
            borderRadius="md"
            sx={BackgroundColor}
            fontSize="sm"
          >
            <Text fontSize="sm" mb={4} fontWeight="bold">
              Driver Code: {data[0]?.DriverCode}
            </Text>
            <Divider borderColor="white" mb={1} />
            <p>Driver: {data[0]?.DriverName}</p>
            <p>Division: {data[0]?.Division}</p>
            <p>Driver Leader: {data[0]?.DriverLeader}</p>
          </GridItem>

          {/* Coaching Details */}
          <GridItem
            p={4}
            shadow="md"
            colSpan={1}
            borderRadius="md"
            sx={BackgroundColor}
            fontSize="sm"
          >
            <Text fontSize="sm" mb={4} fontWeight="bold">
              Coaching Details
            </Text>
            <Divider borderColor="white" mb={1} />
            <p>Status: {data[0]?.Status}</p>
            <p>Request By: {data[0]?.CreatedBy}</p>
            <p>Assigned: {data[0]?.Assigned}</p>
            <p>
              Requested Date:{" "}
              {data[0]?.Created && new Date(data[0]?.Created).toLocaleString()}
            </p>

            <p>
              Completed:{" "}
              {data[0]?.Completed &&
                new Date(data[0]?.Completed).toLocaleString()}
            </p>
          </GridItem>

          {/* Note */}
          <GridItem
            p={4}
            shadow="md"
            colSpan={2}
            borderRadius="md"
            sx={BackgroundColor}
            fontSize="sm"
          >
            <Text fontSize="sm" mb={4} fontWeight="bold">
              Note from the Safety Coach
            </Text>
            <Divider borderColor="white" mb={1} />
            <p>{data[0]?.Note}</p>
          </GridItem>

          {/* Driver Leader Call Information */}
          <GridItem
            p={4}
            shadow="md"
            colSpan={2}
            borderRadius="md"
            sx={BackgroundColor}
            fontSize="sm"
          >
            <Text fontSize="sm" mb={4} fontWeight="bold">
              Information from Driver Leader's Call
            </Text>
            <Divider borderColor="white" mb={1} />
            <p>{data[0]?.Comment}</p>
          </GridItem>
        </Grid>
      </HStack>
    );
  } else {
    return <ErrorMessage />;
  }
}
