import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { SafetyCoachingDetail, SafetyCoachingEvent } from "../types/Types";
import SpeedReport from "../components/Session/SpeedReport";
import {
  Grid,
  GridItem,
  Text,
  HStack,
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Divider,
  Textarea,
  Button,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  UseToastOptions,
  RadioGroup,
  Stack,
  Container,
  Icon,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
} from "@chakra-ui/react";
import ErrorMessage from "../components/Session/NotFound";
import Loading from "../components/Global/LoadingScreen";
import { API, Payload } from "../api/api";
import { ReactElement } from "react";
import { IoIosTimer } from "react-icons/io";

const BackgroundColor = {
  backdropFilter: "blur(16px) saturate(200%)",
  WebkitBackdropFilter: "blur(16px) saturate(200%)",
  backgroundColor: "rgba(17, 25, 40, 0.50)",
  borderRadius: "12px",
  border: "1px solid rgba(255, 255, 255, 0.125)",
  color: "rgba(255, 255, 255, 0.85)",
};

interface CardProps {
  heading: string;
  description: string;
  icon: ReactElement;
  onClick?: () => void;
}

interface SessionProps {
  powerBiToken: string | null;
}

const Card = ({ heading, description, icon, onClick }: CardProps) => {
  return (
    <Box
      maxW={{ base: "full", md: "275px" }}
      w={"full"}
      borderRadius="lg"
      overflow="hidden"
      p={5}
      textAlign="center"
      transition="box-shadow 0.3s, cursor 0.2s"
      boxShadow="xs"
      cursor="pointer"
      border="2px solid rgba(255, 255, 255, 0.2)"
      _hover={{
        boxShadow: "2xl",
      }}
      onClick={onClick}
    >
      <Box
        w={10}
        h={10}
        rounded={"full"}
        display="flex"
        alignItems="center"
        justifyContent="center"
        mx="auto"
      >
        {icon}
      </Box>
      <Stack spacing={4} mt={3}>
        <Heading size="md" textAlign="center">
          {heading}
        </Heading>
        <Text fontSize={"sm"}>{description}</Text>
      </Stack>
    </Box>
  );
};

const Session = ({ powerBiToken }: SessionProps) => {
  const { ID } = useParams<{ ID: string }>();
  const [data, setData] = useState<SafetyCoachingDetail[]>([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState("0");
  useState(false);
  const toast = useToast();
  const [filteredEvents, setFilteredEvents] = useState<SafetyCoachingEvent[]>(
    []
  );
  const [isSpeedReportModalOpen, setIsSpeedReportModalOpen] = useState(false);

  const openSpeedReportModal = () => {
    setIsSpeedReportModalOpen(true);
  };

  const closeSpeedReportModal = () => {
    setIsSpeedReportModalOpen(false);
  };

  const handleAttemptedContactClick = async () => {
    setLoading(true);

    const payload = {
      Process: "Attempt",
      ID: ID,
    };

    try {
      const responseData = await API(payload);
      if (responseData.Success) {
        navigate("/Success");
      } else {
        toast({
          title: "Error",
          description: "An error occurred while processing your request.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Something went wrong.",
        status: "error" as UseToastOptions["status"],
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitClick = async () => {
    if (!comment.trim()) {
      toast({
        title: "Comment required",
        description:
          "Please enter a comment detailing your conversation with the driver.",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    if (rating === "0") {
      toast({
        title: "Rating required",
        description: "Please provide a valid rating for the conversation.",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    setLoading(true);

    const payload = {
      Process: "Complete",
      ID: ID,
      Comment: comment,
      Rating: rating,
    };

    console.log(payload);
    try {
      const responseData = await API(payload);
      if (responseData.Success) {
        navigate("/Success");
      } else {
        toast({
          title: "Error",
          description: "An error occurred while processing your request.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Something went wrong.",
        status: "error" as UseToastOptions["status"],
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const payload: Payload = {
      Process: "Read",
      ID: ID,
    };
    console.log(payload);
    API(payload)
      .then((responseData) => {
        setData(responseData);
        setLoading(false);
        console.log(responseData);
      })
      .catch((error) => {
        setLoading(false);

        console.error("API call error:", error);
      });
  }, [ID]);

  useEffect(() => {
    const filtered = data[0]?.Events.filter((event) => event.ID === ID);
    setFilteredEvents(filtered || []);
  }, [data, ID]);

  console.log(powerBiToken);

  return (
    <>
      {loading ? (
        <Loading />
      ) : data[0]?.Success ? (
        <HStack spacing={1} align="stretch" maxW="98%" mx="auto" p={4}>
          <Box
            p={5}
            bg="gray.50"
            borderRadius="md"
            boxShadow="md"
            width="60%"
            sx={BackgroundColor}
          >
            <Tabs size="sm" isFitted variant="enclosed">
              <TabList>
                <Tab _selected={{ color: "white", bg: "orange" }}>
                  Current Safety Events
                </Tab>
                <Tab _selected={{ color: "white", bg: "orange" }}>
                  All Safety Events
                </Tab>
                
              </TabList>

              <TabPanels>
                <TabPanel>
                  <Box
                    width="100%"
                    height="550px"
                    position="relative"
                    overflowY="auto"
                  >
                    <Table variant="simple" size="sm">
                      <Thead position="sticky" top="0" bg="white" zIndex="1">
                        <Tr>
                          <Th color="blue.300">Title</Th>
                          <Th color="blue.300">Code</Th>
                          <Th color="blue.300">Requested By</Th>
                          <Th color="blue.300">Description</Th>
                          <Th color="blue.300">Event ID</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {filteredEvents.map((event, index) => (
                          <Tr key={index}>
                            <Td>{event.Title}</Td>
                            <Td>{event.Code}</Td>
                            <Td>{event.CreatedBy}</Td>
                            <Td>{event.Description}</Td>
                            <Td>{event.EventID}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Box>
                </TabPanel>
                <TabPanel>
                  <Box
                    width="100%"
                    height="550px"
                    position="relative"
                    overflowY="auto"
                  >
                    <Table variant="simple" size="sm">
                      <Thead position="sticky" top="0" bg="white" zIndex="1">
                        <Tr>
                          <Th color="blue.300">Title</Th>
                          <Th color="blue.300">Code</Th>
                          <Th color="blue.300">Requested By</Th>
                          <Th color="blue.300">Description</Th>
                          <Th color="blue.300">Event ID</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {data[0]?.Events.map((event, index) => (
                          <Tr key={index}>
                            <Td>{event.Title}</Td>
                            <Td>{event.Code}</Td>
                            <Td>{event.CreatedBy}</Td>
                            <Td>{event.Description}</Td>
                            <Td>{event.EventID}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Box>
                </TabPanel>
               
              </TabPanels>
            </Tabs>
          </Box>
          <Grid templateColumns="repeat(2, 1fr)" gap={1} flex="1">
            {/* Driver's Personal Details */}
            <GridItem
              p={4}
              shadow="md"
              colSpan={1}
              borderRadius="md"
              sx={BackgroundColor}
              fontSize="sm"
            >
              <Text fontSize="sm" mb={4} fontWeight="bold">
                Driver Code: {data[0]?.DriverCode}
              </Text>
              <Divider borderColor="white" mb={1} />
              <p>Driver: {data[0]?.DriverName}</p>
              <p>Phone: {data[0]?.DriverPhone}</p>
              <p>Email: {data[0]?.DriverEmail}</p>
            </GridItem>

            {/* Coaching Details */}
            <GridItem
              p={4}
              shadow="md"
              colSpan={1}
              borderRadius="md"
              sx={BackgroundColor}
              fontSize="sm"
            >
              <Text fontSize="sm" mb={4} fontWeight="bold">
                Coaching Details
              </Text>
              <Divider borderColor="white" mb={1} />
              <p>
                Created:{" "}
                {data[0]?.Created &&
                  new Date(data[0]?.Created).toLocaleString()}
              </p>

              <p>Assigned: {data[0]?.Assigned}</p>
            </GridItem>

            {/* Note */}
            <GridItem
              p={4}
              shadow="md"
              colSpan={2}
              borderRadius="md"
              sx={BackgroundColor}
              fontSize="sm"
            >
              <Text fontSize="sm" mb={4} fontWeight="bold">
                Note from the Safety Coach
              </Text>
              <Divider borderColor="white" mb={1} />
              <p>{data[0]?.Note}</p>
            </GridItem>
            {/* Safety Tools */}
            <GridItem
              p={4}
              shadow="md"
              colSpan={2}
              borderRadius="md"
              sx={BackgroundColor}
              fontSize="sm"
            >
              <Container maxW={"5xl"}>
                <Flex flexWrap="wrap" gridGap={6}>
                  {/* Card for Speed Analytics */}
                  <Card
                    heading={"Speeding Analytics"}
                    icon={<Icon as={IoIosTimer} w={10} h={10} />}
                    description={"A look at driver's speeding events"}
                    onClick={openSpeedReportModal}
                  />
                </Flex>
              </Container>
            </GridItem>
            {/* Driver Leader Call Information */}
            <GridItem
              p={4}
              shadow="md"
              colSpan={2}
              borderRadius="md"
              sx={BackgroundColor}
              fontSize="sm"
            >
              <Text fontSize="sm" mb={4} fontWeight="bold">
                Please provide information about your talk with the driver
              </Text>

              <RadioGroup
                mb={4}
                display="flex"
                alignItems="center"
                justifyContent="space-around"
              >
                <Stack direction="row" spacing={5}>
                  <label
                    style={{
                      cursor: "pointer",
                      opacity: rating === "1" ? 1 : 0.5,
                      textDecoration: "none",
                    }}
                    onClick={() => {
                      setRating("1");
                      console.log("Rating: 1");
                    }}
                  >
                    <Text fontSize="2xl">😞</Text>
                  </label>
                  <label
                    style={{
                      cursor: "pointer",
                      opacity: rating === "2" ? 1 : 0.5,
                      textDecoration: "none",
                    }}
                    onClick={() => {
                      setRating("2");
                      console.log("Rating: 2");
                    }}
                  >
                    <Text fontSize="2xl">😐</Text>
                  </label>
                  <label
                    style={{
                      cursor: "pointer",
                      opacity: rating === "3" ? 1 : 0.5,
                      textDecoration: "none",
                    }}
                    onClick={() => {
                      setRating("3");
                      console.log("Rating: 3");
                    }}
                  >
                    <Text fontSize="2xl">😊</Text>
                  </label>
                </Stack>
              </RadioGroup>
              <Textarea
                placeholder="Enter your comment here..."
                focusBorderColor="white"
                size="sm"
                resize="vertical"
                minH="100px"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
              <Flex justify="space-between" alignItems="center" mt={5}>
                <Button
                  colorScheme="whiteAlpha"
                  size="sm"
                  variant="outline"
                  textColor="white"
                  onClick={handleAttemptedContactClick}
                >
                  Attempted Contact
                </Button>
                <Button
                  colorScheme="whiteAlpha"
                  size="sm"
                  variant="outline"
                  textColor="orange"
                  onClick={handleSubmitClick}
                >
                  Submit
                </Button>
              </Flex>
            </GridItem>
          </Grid>
        </HStack>
      ) : (
        <ErrorMessage />
      )}
      {/* Speed Analytics Modal */}
      <Modal
        size="full"
        isOpen={isSpeedReportModalOpen}
        onClose={closeSpeedReportModal}
      >
        <ModalOverlay bg="rgba(0, 0, 0, 0.7)" />
        <ModalContent bg="rgba(0, 0, 0, 0.7)">
          <ModalHeader color="whiteAlpha.600">
            {data[0]?.DriverCode} Speeding Analytics
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody p={0}>
            <SpeedReport
              powerBiToken={powerBiToken}
              DriverCode={data[0]?.DriverCode}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Session;
